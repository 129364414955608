import { states } from '@/components/shared/configuration/states'

export function isValidUrl(urlString: string): boolean {
	let url
	try {
		url = new URL(urlString)
	} catch (_) {
		return false
	}
	return url.protocol === 'http:' || url.protocol === 'https:'
}

export function formatPhoneNumber(phoneNumber: string) {
	const cleaned = ('' + phoneNumber).replace(/\D/g, '')
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return match[2] + '-' + match[3] + '-' + match[4]
	}
	return ''
}

export function debounce<T>(fn: T, wait: number) {
	let timer: ReturnType<typeof setTimeout>
	return (event: Event) => {
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(() => {
			if (typeof fn === 'function') {
				fn(event)
			}
		}, wait)
	}
}

export function isAlphaNumeric(str: string) {
	let code
	let i
	let len

	for (i = 0, len = str.length; i < len; i++) {
		code = str.charCodeAt(i)
		if (
			!(code > 47 && code < 58) && // numeric (0-9)
			!(code > 64 && code < 91) && // upper alpha (A-Z)
			!(code > 96 && code < 123) // lower alpha (a-z)
		) {
			return false
		}
	}
	return true
}

export function getStateNamesByCodes(codes: string[]) {
	const filteredStates = states.filter(state => codes.includes(state.code))
	const stateNames = filteredStates.map(state => state.name)

	return stateNames
}

// Used to format user client search and display list
export function formatClientListItem(client) {
	const { name, firstName, lastName } = client

	// Return name to avoid returning duplicate names and first/last names
	if (name && name.toLowerCase() === `${firstName} ${lastName}`.toLowerCase()) {
		return name
	}

	// Return all three but segregate with a colon
	if (name && firstName && lastName) {
		return `${name} : ${firstName} ${lastName}`
	}

	// Otherwise, return existing properties
	return [name, firstName, lastName].filter(Boolean).join(' ')
}
